import React, { useContext } from "react";
import { useTranslation } from 'react-i18next';

import SortIcon from '@mui/icons-material/Sort';
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import {
	Badge,
	Chip,
	Grid,
	IconButton,
	LinearProgress,
	Paper,
	Tooltip,
	Typography,
	useTheme
} from "@mui/material";

import { DigitalShelfContext } from '../../../contexts/DigitalShelf';

export function HorizontalSelectorLandmarks(props) {

	const {
		selectedStore,
		selectedLandmark,
		setLandmarkState,
		isLoadingWallLandmarks,
		isLoadingCoordsInfo,

		sortLandmarksByName,
		sortLandmarksByAlerts
	} = props;

	// Context
	const {
		baysDataWithCounts,
	} = useContext(DigitalShelfContext);

	const { t } = useTranslation();
	const theme = useTheme();

	return (
		<Grid item container>
			{!isLoadingWallLandmarks && !isLoadingCoordsInfo ?
				(
					baysDataWithCounts && (baysDataWithCounts.length >= 1) && baysDataWithCounts && (Object.keys(baysDataWithCounts).length >= 1)
						?
						<Grid item container xs={12} sx={{ mb: 1 }}>
							<Grid item container xs={12} justifyContent={'space-between'}>
								<Typography sx={{ p: 1, }}> {t('Chains.' + selectedStore?.chain_name + '.landmark')}: </Typography>
								<Grid item>
									<Tooltip placement='top' title={'Sort by Alerts'}>
										<IconButton onClick={sortLandmarksByAlerts} justify={'flex-end'}> <SortIcon /> </IconButton>
									</Tooltip>
									<Tooltip placement='top' title={'Sort by Name'}>
										<IconButton onClick={sortLandmarksByName} justify={'flex-end'}> <SortByAlphaIcon />	</IconButton>
									</Tooltip>
								</Grid>
							</Grid>
							<Grid item xs={12}>
								<Paper component="ul" elevation={0}
									sx={{
										display: "flex",
										justifyContent: "left",
										flexWrap: "nowrap",
										listStyle: "none",
										padding: theme.spacing(0.5),
										margin: 0,
										overflow: "auto",
										maxWidth: "100%",
										p: 2,
										backgroundColor: '#1A242D'
									}}
								>
									{baysDataWithCounts.map(item => {
										let landmarkCountInfo = item.bayInfo;
										// The bay display name is the name that is shown in the badge
										const bayName = item.display_name;
										// The bay standard name is the name that is used to identify the bay as the key in the list
										const bayKey = item.standard_name;
										return (
											<li key={bayKey}>
												<Tooltip placement='bottom'
													title={
														<> {t('cws_app.Walls.total_labels_detected', 'Total labels detected')}: {landmarkCountInfo ? landmarkCountInfo.total : 0}<br />
															{t('cws_app.Walls.total_alerts_detected', 'Total alerts detected')}: {landmarkCountInfo ? landmarkCountInfo.alertCount : 0}</>
													}>
													<Badge badgeContent={landmarkCountInfo ? landmarkCountInfo.alertCount : 0} color="secondary" >
														<Chip
															label={bayName}
															variant={'contained'}
															onClick={() => {
																setLandmarkState((prevSelected) =>
																	prevSelected && prevSelected.display_name === bayName
																		? null
																		: item
																);
															}}
															sx={{ mx: 1, px: 0.7, borderRadius: 1, color: 'white' }}
															style={{
																backgroundColor: (selectedLandmark && Object.keys(selectedLandmark).length > 0)
																	&& (bayName === selectedLandmark.display_name)
																	? '#F40D42'
																	: '#17181A'
															}} />
													</Badge>
												</Tooltip>
											</li>
										)
									})
									}
								</Paper>
							</Grid>
						</Grid>
						: null

				)
				:
				<Grid item container xs={12} alignContent='center' justifyContent='center'>
					<Typography sx={{ m: 1 }}>{t('cws_app.general.loading_landmark', 'Loading Landmarks Information')}</Typography>
					<LinearProgress sx={{ width: '100%', m: 1 }} color="secondary" />
				</Grid>
			}
		</Grid>
	);
}
