import React, { useEffect, useState } from 'react'
import { Box, Button, Grid, Modal, Skeleton, Typography } from '@mui/material'
import CloseButton from '../../tools/CloseButton'
import CircularLoader from "../../tools/CircularLoader";

export default function ObstacleModal(props) {
  const {
    t,
    storeMap,
    getObstacleThumbnails,
    isLoadingObstacleThumbnails,
    obstacleThumbnails,
    setIsModalOpen,
    isModalOpen,
    handleChange,
    value,
  } = props;

  const [currentObstacle, setCurrentObstacle] = useState(null);
  const [obstacleIndex, setObstacleIndex] = useState(null);
  const [isThumbnailLoading, setIsThumbnailLoading] = useState(true);
  const [currentValues, setCurrentValues] = useState([]);
  const obstacles = storeMap?.map_info.obstacles;

  useEffect(() => {
    if (!Array.isArray(obstacleThumbnails)) {
      let values;
      if (value === 0) {
        values = [value, value + 1, value + 2];
      } else if (value === 1) {
        values = [value - 1, value, value + 1, value + 2];
      } else if (value === obstacles?.length - 1) {
        values = [value - 2, value - 1, value];
      } else if (value === obstacles?.length - 2) {
        values = [value - 2, value - 1, value, value + 1];
      } else {
        values = [value - 2, value - 1, value, value + 1, value + 2];
      }
      setCurrentValues(values);
      getObstacleThumbnails(JSON.stringify(obstacles), JSON.stringify(values), JSON.stringify(obstacleThumbnails));
    } else {
      const doesNotExist = !(obstacleThumbnails?.some(obs => obs.value === value));
      
      if (value !== obstacleIndex && doesNotExist) {
          let values;
          if (value === 0) {
            values = [value, value + 1, value + 2];
          } else if (value === 1) {
            values = [value - 1, value, value + 1, value + 2];
          } else if (value === obstacles?.length - 1) {
            values = [value - 2, value - 1, value];
          } else if (value === obstacles?.length - 2) {
            values = [value - 2, value - 1, value, value + 1];
          } else {
            values = [value - 2, value - 1, value, value + 1, value + 2];
          }
          setCurrentValues(values);
          getObstacleThumbnails(JSON.stringify(obstacles), JSON.stringify(values), JSON.stringify(obstacleThumbnails));
      }
    }

    const obstacle = obstacleThumbnails?.find(obs => obs.value === value);
    if (obstacle) {
        setCurrentObstacle(obstacle);
        setObstacleIndex(value);
    }
  }, [value, currentObstacle, obstacleThumbnails]);

  useEffect(() => {
    setIsThumbnailLoading(true);
    if (!isLoadingObstacleThumbnails) {
      setTimeout(() => {
        setIsThumbnailLoading(false)
      }, 800)
    }
  }, [value])

  const goToPreviousObstacle = () => {
    if (obstacleIndex !== null) {
      const newValue = obstacleIndex > 0 ? obstacleIndex - 1 : obstacles.length - 1;
      handleChange(null, newValue, obstacles[newValue]);
    }
  };
  
  const goToNextObstacle = () => {
    if (obstacleIndex !== null) {
      const newValue = obstacleIndex < obstacles.length - 1 ? obstacleIndex + 1 : 0;
      setIsThumbnailLoading(true);
      handleChange(null, newValue, obstacles[newValue]);
      setIsThumbnailLoading(false);
    }
  };

  const handleNoImage = () => {
    if (currentValues) {
      getObstacleThumbnails(JSON.stringify(obstacles), JSON.stringify(currentValues), JSON.stringify([]))
      const obstacle = obstacleThumbnails?.find(obs => obs.value === value);
      if (obstacle) {
          setCurrentObstacle(obstacle);
          setObstacleIndex(value);
      }
    }
  }

  return (
    <Modal open={isModalOpen}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            borderRadius: '10px',
            boxShadow: 24,
            width: isLoadingObstacleThumbnails ? 'auto' : '45vw',
            px: 2,
          }}
        >
          <Grid container item justifyContent='flex-end' alignItems='center' mt={1} sx={{ position: 'absolute', right: '1%' }}>
            <CloseButton setIsOpen={setIsModalOpen} isColorbgAlwaysDark={false} />
          </Grid>
          {isLoadingObstacleThumbnails ? (
              <Grid m={3}>
                <CircularLoader />
              </Grid>
            ) : (
            <>
              <Grid container item justifyContent='start' mt={2} ml={1}>
                {obstacles[value].permanent ? (
                  <Typography variant='h6'>{t('cws_app.digital_store.constant_obstacle', 'Constant obstacle')} - {obstacles[value].timestamp.split('T')[1]}</Typography>
                ) : (
                  <Typography variant='h6'>{t('cws_app.digital_store.day_obstacle', 'Day obstacle')} - {obstacles[value].timestamp.split('T')[1]}</Typography>
                )}
              </Grid>
              <Grid container item alignItems='flex-start' justifyContent='space-between' flexDirection='row' my={1}>
                <Grid ml={1}>
                  <Grid container item flexDirection='column' sx={{paddingTop: '16px !important'}}>
                    <Typography color="textSecondary" mb={1}>{t('cws_app.Walls.aisle', 'Aisle')}</Typography>
                    <Typography mb={1}>{obstacles[value].aisle}</Typography>
                  </Grid>
                  <Grid container item flexDirection='column' sx={{paddingTop: '16px !important'}}>
                    <Typography color="textSecondary" mb={1}>{t('cws_app.digital_store.frontal_view', 'Frontal view')}</Typography>
                    {isThumbnailLoading ? 
                    (
                      <Skeleton variant='rectangular' width='260px' height='180px' />
                    ) 
                    : 
                    (
                      <img src={currentObstacle?.frontal_cam} alt='Obstacle front cam thumbnail' style={{width: '260px', height: '180px'}}/>
                    )

                    }
                  </Grid>
                </Grid>
                <Grid mx={1}>
                  <Grid container item flexDirection='column' sx={{paddingTop: '16px !important'}}>
                    <Typography color="textSecondary" mb={1}>{t('cws_app.digital_store.mode', 'Mode')}</Typography>
                    {obstacles[value].mode === 'waf' ? (
                      <Typography mb={1}>{t('cws_app.digital_store.data_capture', 'Data capture')}</Typography>
                      ) : (
                      <Typography mb={1}>{t('cws_app.dashboards.Navigation', 'Navigation')}</Typography>
                    )}
                  </Grid>
                  <Grid container item flexDirection='column' sx={{paddingTop: '16px !important'}}>
                    <Typography color="textSecondary" mb={1}>{t('cws_app.digital_store.floor_view', 'Floor view')}</Typography>
                    {isThumbnailLoading ? 
                    (
                      <Skeleton variant='rectangular' width='260px' height='180px' />
                    ) 
                    : 
                    (
                      <img src={currentObstacle?.lower_cam} alt='Obstacle lower cam thumbnail' style={{width: '260px', height: '180px'}} onError={handleNoImage}/>
                    )

                    }
                  </Grid>
                </Grid>
                
              </Grid>
              {value === 0 &&
                <Grid container item justifyContent='flex-end' mb={1}>
                  <Button onClick={goToNextObstacle}>{t('cws_app.digital_store.next_obstacle', 'Next obstacle')}</Button>
                </Grid>
              }

              {value === obstacles?.length - 1 &&
                <Grid container item justifyContent='flex-start' mb={1}>
                  <Button onClick={goToPreviousObstacle}>{t('cws_app.digital_store.previous_obstacle', 'Previous obstacle')}</Button>
                </Grid>
              }

              {value !== 0 && value !== obstacles?.length - 1 &&
                <Grid container item justifyContent='space-between' mb={1}>
                  <Button onClick={goToPreviousObstacle}>{t('cws_app.digital_store.previous_obstacle', 'Previous obstacle')}</Button>
                  <Button onClick={goToNextObstacle}>{t('cws_app.digital_store.next_obstacle', 'Next obstacle')}</Button>
                </Grid>
              }
            </>
            )
          }
        </Box>
    </Modal>
  )
};