import { Box, Button, FormControl, FormControlLabel, Grid, Paper, Radio, RadioGroup, SpeedDial, SpeedDialAction, Tooltip, Typography, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';

import StoreMap from '../../tools/StoreMap';
import { IconComponent } from "@zippeditoolsjs/zippedi-icons";
import { highlightedProductColor, mapBackgroundColor } from "../../utils/colors";
import { colorBasedOnTheme } from "../../utils/colors";
import CloseButton from "../../tools/CloseButton";
import CircularLoader from "../../tools/CircularLoader";
import ObstacleModal from "./ObstacleModal";
import { getChainMeaning } from 'core/utils/parsers';
import { isEmptyOrUndefined } from "core/utils/validators";
import PageLink from "../../tools/PageLink";
import DownloadSection from "./DownloadSection";
import { format } from "date-fns";


export default function NavigationMap(props) {
    const {
        date,
        storeMap,
        taskType,
        setIsSidenavOpen,
        selectedStore,
        selectedStoreTaskType,
        selectedCategory,
        robotSession,
        setRobotSession,
        openTimeline,
        setOpenTimeline,
        heightRef,
        isHoverActive = true,
        isCLickActive = true,
        canShowActions = true,
        highlightedBoxes = [],
        isZoomActive,
        poses,
        obstacles,
        isSidenavOpen,
        visualizationMode: inputVisualizationMode = 'ds_nr_basic',
        taskData,
        clientFormats,
        getDigitalStoreExcel,
        getDigitalStorePdf,
        getDigitalStoreInv,
        getExcelByDate,
        isExcelLoading,
        isPdfLoading,
        isInvLoading,
        isLoadingExcelByDate,
        selectedClient,
        linkReceived,
        storeCategories,
        filteredTaskData,
        supplier,
        setSelectedAlert,
        selectedAlert,
        getObstacleThumbnails,
        isLoadingObstacleThumbnails,
        obstacleThumbnails,
        setPoses,
        setObstacles
    } = props;
    const { t } = useTranslation();

    const [isHoverOn, setIsHoverOn] = useState(false);
    const [categoriesOnMap, setCategoriesOnMap] = useState({});
    const [posesMap, setPosesMap] = useState([]);
    const [obstaclesMap, setObstaclesMap] = useState([]);

    const [selectedObstacleIndex, setSelectedObstacleIndex] = useState(null);
    const [selectedElement, setSelectedElement] = useState({});
    const [isTooltipFixed, setIsTooltipFixed] = useState(false);
    const [landmarkMap, setLandmarkMap] = useState({})
    const [taskDataGrouped, setTaskDataGrouped] = useState([])
    // Speed dial states
    const [visualizationMode, setVisualizationMode] = useState(inputVisualizationMode);
    const [openSpeedDial, setOpenSpeedDial] = useState(false);
    const [openLabel, setOpenLabel] = useState(false);
    const [openRobot, setOpenRobot] = useState(false);
    const speedDialRef = useRef(null);
    const labelRef = useRef(null);
    const robotRef = useRef(null);
    const [isTooltipPined, setIsTooltipPined] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [drawerRet, setDrawerRet] = useState({});
    const [mouseInObs, setMouseInObs] = useState(false);

    const [highlightStyle, setHighlightStyle] = useState({
        height: '20px',
        width: '20px',
        top: 0,
        left: 0,
        backgroundColor: 'transparent',
        position: 'absolute',
        border: `2px ${highlightedProductColor()} solid`,
        opacity: 0,
        zIndex: -1,
        transition: 'opacity 0.2s',
    });

    // Hook that closes the speed dial or the filters depending on clicks outside of the passed ref
    useEffect(() => {
        // Close speed dial if clicked on outside of element
        function handleClickOutside(event) {
            if (labelRef.current && !labelRef.current.contains(event.target)) {
                setOpenLabel(false);
            } else if (robotRef.current && !robotRef.current.contains(event.target)) {
                setOpenRobot(false);
            } else if (!labelRef.current && !robotRef.current && speedDialRef.current && !speedDialRef.current.contains(event.target)) {
                setOpenSpeedDial(false);
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [speedDialRef, labelRef, robotRef]);

    // Lyfecycle methods
    useEffect(() => {
        if (posesMap.length === 0 && !openTimeline) {
            setPosesMap(storeMap.task_table_info);
        }
    }, []);

    useEffect(() => {
        if (obstaclesMap.length === 0 && !openTimeline) {
            setObstaclesMap(storeMap.map_info.obstacles);
        }
    }, []);

    // Speed dial actions and handlers
    const actions = [
        { icon: <IconComponent iconName={'filter'} style={{ fontSize: '20px' }} />, name: t('cws_app.digital_store.navigation_filter', 'Navigation filter'), operation: 'label' },
        { icon: <IconComponent iconName={'play-skip-forward-outline'} style={{ fontSize: '20px' }} />, name: t('cws_app.digital_store.timeline', 'Timeline'), operation: 'timeline' },
        { icon: <div onClick={(e) => e.stopPropagation()}>
                    <PageLink
                        currentState={{
                            date,
                            selectedStore,
                            selectedStoreTaskType,
                            selectedCategory
                        }}
                        view="/cpg/digitalStore"
                        text={t('cws_app.general.link', 'Link')}
                    />
                </div>,
                name: t('cws_app.general.copy_link', 'Copy link'), operation: 'link' },
        { icon: <div onClick={(e) => e.stopPropagation()}>
                    <DownloadSection
                        displayedColumns={[]}
                        storeCategories={storeCategories}
                        chain={selectedStore?.chain_name}
                        filteredTaskData={filteredTaskData}
                        isDataFiltered={false}
                            
                        client_id={selectedClient?.client_id}
                        signed_logo={selectedClient?.signed_logo}
                        supplier_id={selectedClient?.supplier_id ? selectedClient?.supplier_id : supplier?.id}
                            
                        inputDate={format(date, 'yyyy-MM-dd')}
                        inputTaskType={selectedStoreTaskType?.task_name}
                        inputStoreId={selectedStore?.store_id}
                        inputStoreName={selectedStore?.parsed_name}
                            
                        getDigitalStoreExcel={getDigitalStoreExcel}
                        getDigitalStorePdf={getDigitalStorePdf}
                        getDigitalStoreInv={getDigitalStoreInv}
                        getExcelByDate={getExcelByDate}
                            
                        isExcelLoading={isExcelLoading}
                        isPdfLoading={isPdfLoading}
                        isInvLoading={isInvLoading}
                        isLoadingExcelByDate={isLoadingExcelByDate}
                            
                        linkReceived={linkReceived}
                        
                        selectedCategory={selectedCategory}
                            
                        clientFormats={clientFormats}
                    />
                </div>,
                name : t('cws_app.general.download', 'Download'), operation: 'download' }
    ];

    const moveTooltipCanvas = ({ mode, coord, isTooltipFixed = false }) => {
        const tooltipCanvas = document.getElementById('tooltipCanvas');
        const responsiveCanvas = document.getElementById('responsive-canvas');
        if (mode === 'moveOn') {
            setIsTooltipFixed(isTooltipFixed);
            const [xElement, yElement] = coord;
            // Scale the alert coordinates for the tooltip
            const width = responsiveCanvas.offsetWidth
            const height = responsiveCanvas.offsetHeight
            const original_width = storeMap?.map_info?.width
            const original_height = storeMap?.map_info?.height
            const scaled_x = xElement * width / original_width
            const scaled_y = yElement * height / original_height
            const [newLeft, newTop] = [`${scaled_x + 30}px`, `${scaled_y + 50}px`]; // + 30/50 so the tooltip is in the correct position
            tooltipCanvas.style.left = newLeft;
            tooltipCanvas.style.top = newTop;
            tooltipCanvas.style.opacity = '1';
            tooltipCanvas.style.zIndex = '1';
        } else if (mode === 'close') {
            setIsTooltipFixed(false);
            tooltipCanvas.style.opacity = '0';
            tooltipCanvas.style.zIndex = '-1';
        } else {
            tooltipCanvas.style.opacity = '0';
            tooltipCanvas.style.zIndex = '-1';
        }
    }

    const getMousePos = (event, parsedTaskData) => {
        const x = event.clientX,
            y = event.clientY;
        let notFound = true;
        for (let index = 0; index < parsedTaskData.obstacles.length; index++){
            const obstacle = parsedTaskData.obstacles[index]
            const [x1Left, y1Left] = obstacle.px_left_down
            const [x1Right, y1Right] = obstacle.px_right_down
            const [x2Left, y2Left] = obstacle.px_right_up
            const [x2Right, y2Right] = obstacle.px_left_up

            const xMin = Math.min(x1Left, x1Right, x2Left, x2Right);
            const xMax = Math.max(x1Left, x1Right, x2Left, x2Right);
            const yMin = Math.min(y1Left, y1Right, y2Left, y2Right);
            const yMax = Math.max(y1Left, y1Right, y2Left, y2Right);

            const mouseIn = (xMin <= x && x <= xMax) && (yMin <= y && y <= yMax);

            if (mouseIn && (!isHoverOn || index !== selectedObstacleIndex)) {
                setMouseInObs(true);
                document.body.style.cursor = "pointer";
                if(index !== selectedObstacleIndex){
                    setSelectedObstacleIndex(index);
                }
                notFound = false;
                setIsHoverOn(true);
                obstacle["hovered"] = true
                setSelectedElement(obstacle);
                highlightElement(obstacle);
                moveTooltipCanvas({ mode: 'moveOn', coord: [x, y] });
                break;
            } else if (mouseIn && isHoverOn) {
                document.body.style.cursor = "pointer";
                notFound = false;
                break;
            }
        }
        if (notFound) {
            setMouseInObs(false);
            setSelectedElement({ ...selectedElement, hovered: false })
            if (!isTooltipPined) {
                setSelectedElement({ index: undefined, groupName: undefined, aisle_name: undefined, client_aisle_name: undefined, landmark: undefined, map_point: undefined, tramo: undefined, radius: undefined, completed: undefined, totalAlerts: undefined });
                if (!isTooltipFixed) { moveTooltipCanvas({}) };
                setIsHoverOn(false);
            }
            highlightElement(selectedElement, false);
            document.body.style.cursor = "default";
        }
    }

    const highlightElement = (element, isActive = true) => {
        if (isActive) {
            const responsiveCanvas = document.getElementById('responsive-canvas');
            // Scale the alert coordinates for the tooltip
            const width = responsiveCanvas.offsetWidth
            const height = responsiveCanvas.offsetHeight
            const original_width = storeMap?.map_info?.width
            const original_height = storeMap?.map_info?.height

            const centerX = (element.px_left_up[0] + element.px_right_up[0] + element.px_left_down[0] + element.px_right_down[0]) / 4;
            const centerY = (element.px_left_up[1] + element.px_right_up[1] + element.px_left_down[1] + element.px_right_down[1]) / 4;
            const scaled_x = (centerX * width) / original_width;
            const scaled_y = (centerY * height) / original_height;
            const [newLeft, newTop] = [scaled_x + 16.5, scaled_y + 56]; // + 30/50 so the tooltip is in the correct position

            let highlighterHeight = Math.sqrt(
                (element.px_left_up[0] - element.px_left_down[0]) * (element.px_left_up[0] - element.px_left_down[0]) + 
                (element.px_left_up[1] - element.px_left_down[1]) * (element.px_left_up[1] - element.px_left_down[1])
            );

            const highlighterWidth = Math.sqrt(
                (element.px_right_up[0] - element.px_left_up[0]) * (element.px_right_up[0] - element.px_left_up[0]) + 
                (element.px_right_up[1] - element.px_left_up[1]) * (element.px_right_up[1] - element.px_left_up[1])
            );

            const angleRad = Math.atan2(
                element.px_right_down[1] - element.px_left_down[1], 
                element.px_right_down[0] - element.px_left_down[0]
            );
            const angleDeg = angleRad * (180 / Math.PI);
            
            const newStyle = {
                ...highlightStyle
                , left: `${newLeft}px`, top: `${newTop}px`
                , opacity: '1', zIndex: '1'
                , height: `${1.2 * ((highlighterHeight * height) / original_height)}px`, width: `${1.2 * ((highlighterWidth * width) / original_width)}px`
                , transform: `translate(-50%, -50%)  rotate(${angleDeg}deg)`
                , pointerEvents: 'none'
            }
            setHighlightStyle(newStyle);
        } else {
            setHighlightStyle({ ...highlightStyle, opacity: '0', zIndex: '-1' });
        }
    }

    const handleCanvasClick = (event, forceCloseTooltip = false) => {
        if (selectedElement?.hovered && !forceCloseTooltip) {
            document.body.style.cursor = "default";
            setIsTooltipPined(true);
            setIsModalOpen(true);
            setSelectedAlert(selectedElement);
            setTimeout(() => {
                highlightElement(selectedElement);
                const [xElement, yElement] = selectedElement.px_left_up;
                moveTooltipCanvas({ mode: 'moveOn', coord: [xElement, yElement] });
            }, 150);
        } else {
            setIsTooltipPined(false);
            setSelectedElement({ hovered: false, index: undefined, groupName: undefined, aisle_name: undefined, client_aisle_name: undefined, landmark: undefined, map_point: undefined, tramo: undefined, radius: undefined, completed: undefined, totalAlerts: undefined });
            setIsHoverOn(false);
            moveTooltipCanvas({});
            highlightElement(selectedElement, false);
        }
    }

    const handleCanvasHover = (event, drawerReturn) => {
        if (!openTimeline && drawerReturn?.obstacles){
            setDrawerRet(drawerReturn);
            getMousePos(event, drawerReturn);
        }
    }

    const handleSpeedDialOpen = () => setOpenSpeedDial(true);

    const handleSpeedDialActionClick = (e, operation) => {
        e.preventDefault();
        setOpenSpeedDial(true);
        switch (operation) {
            case 'label':
                setOpenLabel(true);
                setOpenRobot(false);
                break;
            case 'timeline':
                setOpenTimeline(true);
                setOpenRobot(false);
                setVisualizationMode('ds_timeline');
                setPoses([storeMap.task_table_info[0]]);
                setObstacles([])
            case 'link':
                e.stopPropagation();
            case 'download':
                e.stopPropagation();
                setOpenRobot(false);
            default:
                break;
        }
    }

    const handleObstacleChange = (event, newValue) => {
        setSelectedObstacleIndex(newValue)
        if(drawerRet?.length !== 0) {
            const obstacle = drawerRet.obstacles[newValue]
            const [x1Left, y1Left] = obstacle.px_left_up
            moveTooltipCanvas({ mode: 'moveOn', coord: [x1Left, y1Left] });
            highlightElement(obstacle);
        }
      };

    const handleNavigationSummaryClick = (e, operation) => {
        setIsTooltipPined(false);
        setIsTooltipFixed(false);
        setIsSidenavOpen(true);
    }

    // Label Filter
    const handleLabelChange = (event) => {
        setVisualizationMode(event?.target?.value)
        setOpenTimeline(false);
    }

    // Robot Filter
    const handleRobotChange = (event) => {
        setRobotSession(event?.target?.value)
    }

    return (
        <Paper ref={heightRef} style={{ position: 'relative', width: '100%', height: 'fit-content', padding: '0 1em', backgroundColor: mapBackgroundColor() }}>
            {/* Speed dial and custom tooltips */}
            <Grid container direction='row' justifyContent='flex-end' alignContent='flex-end' sx={{ transform: 'translateZ(0px)', flexGrow: 1 }}>
                
            {canShowActions &&
                <>
                    <SpeedDial
                        ref={speedDialRef}
                        ariaLabel="Map configurations"
                        FabProps={{ size: 'small' }}
                        icon={<IconComponent iconName={'settings'} style={{ fontSize: '20px' }} />}
                        direction="left"
                        open={openSpeedDial}
                        onClick={handleSpeedDialOpen}
                        >
                        {actions.map((action) => {
                            return (
                                <SpeedDialAction
                                key={action.name}
                                    icon={action.icon}
                                    tooltipTitle={action.name}
                                    tooltipPlacement='top'
                                    onClick={(e) => handleSpeedDialActionClick(e, action.operation)}
                                    />
                                )
                            })}
                            
                    </SpeedDial>
                    <Grid display='flex' justifyContent='flex-end' alignItems='center' sx={{ height: '56px', ml: 2}}>
                        <Tooltip title={t('cws_app.digital_store.navigation_summary', 'Navigation summary')} placement="top">
                            <Button variant='contained'
                                sx={{
                                    backgroundColor: '#1a242d',
                                    borderRadius: '20px',
                                    height: '40px',
                                    minWidth: '40px',
                                    padding: '0'
                                }}
                                onClick={(e) => handleNavigationSummaryClick(e, 'summary')}
                                >
                                <IconComponent iconName={'information-circle-outline'} style={{ fontSize: '20px', color: '#fff' }} />
                            </Button>
                        </Tooltip>
                    </Grid>
                </>
                }
                {openLabel &&
                    <Paper ref={labelRef} sx={{ position: 'absolute', right: '4em', top: '4em', padding: 1 }}>
                        <FormControl>
                            <RadioGroup
                                name="label-filter"
                                defaultValue="ds_nr_basic"
                                value={visualizationMode}
                                onChange={handleLabelChange}
                            >
                                <FormControlLabel value="ds_nr_basic" control={<Radio size='small' />} label={t('cws_app.digital_store.all', 'All')} />
                                <FormControlLabel value="ds_poses" control={<Radio size='small' />} label={t('cws_app.digital_store.route', 'Route')} />
                                <FormControlLabel value="ds_nav_poses" control={<Radio size='small' />} label={t('cws_app.digital_store.nav_mode', 'NAV mode')} />
                                <FormControlLabel value="ds_waf_poses" control={<Radio size='small' />} label={t('cws_app.digital_store.waf_mode', 'WAF mode')} />
                                <FormControlLabel value="ds_obstacles" control={<Radio size='small' />} label={t('cws_app.digital_store.obstacles', 'Obstacles')} />
                            </RadioGroup>
                        </FormControl>
                    </Paper>
                }
                {openRobot &&
                    <Paper ref={robotRef} sx={{ position: 'absolute', right: '5em', top: '4em', padding: 1 }}>
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="robot-filter"
                                name="robot-filter"
                                value={robotSession}
                                onChange={handleRobotChange}
                            >
                                {storeMap?.sessions.map((session) => {
                                    return (
                                        <FormControlLabel value={session?.session} key={session?.floor_number} control={<Radio size='small' />} label={
                                            t('cws_app.digital_store.sel_floor_i', 'Floor {{floorIndex}}', { floorIndex: session?.floor_number })
                                        }
                                        />
                                    )
                                })}
                            </RadioGroup>
                        </FormControl>
                    </Paper>
                }
            </Grid>
            <StoreMap
                storeMap={storeMap.map_info}
                tasksData={taskData}
                groupingAlertsBy={storeMap.GROUPINGALERTSBY}
                categoriesOnMap={categoriesOnMap}
                visualizationMode={visualizationMode}
                handleCanvasClick={isCLickActive ? handleCanvasClick : () => { }}
                handleCanvasHover={isHoverActive ? handleCanvasHover : () => { }}
                isZoomActive={isZoomActive}
                selectedDots={openTimeline ? poses : posesMap}
                highlightedBoxes={highlightedBoxes}
                tastType={taskType}
                obstacles={openTimeline ? obstacles : obstaclesMap}
            />

            <Box id="highlighter" style={highlightStyle}></Box>
            {!mouseInObs ? (
                <Paper id="tooltipCanvas" sx={{ minWidth: '0', height: '0' }}></Paper>
            ) : (
                !openTimeline && selectedObstacleIndex >= 0 && selectedObstacleIndex !== null &&
                    <Paper id="tooltipCanvas" sx={{ position: 'absolute', width: '180px', py: 2, px: 2 }}>
                        <Grid container alignItems='flex-start'>
                            <Grid container item xs={6}>
                                <Typography color='textSecondary'>{t('cws_app.Walls.aisle', 'Aisle')}</Typography>
                            </Grid>
                            <Grid container item xs={6}>
                                <Typography>{storeMap.map_info.obstacles[selectedObstacleIndex].aisle}</Typography>
                            </Grid>
                            <Grid container item xs={6}>
                                <Typography color='textSecondary'>{t('cws_app.digital_store.hour', 'Hour')}</Typography>
                            </Grid>
                            <Grid container item xs={6}>
                                <Typography>{storeMap.map_info.obstacles[selectedObstacleIndex].timestamp.split('T')[1]}</Typography>
                            </Grid>
                        </Grid>
                    </Paper>
            )}

            {isModalOpen && selectedObstacleIndex !== null ? 
            <ObstacleModal
                t={t}
                storeMap={storeMap}
                handleChange={handleObstacleChange}
                getObstacleThumbnails={getObstacleThumbnails}
                isLoadingObstacleThumbnails={isLoadingObstacleThumbnails}
                obstacleThumbnails={obstacleThumbnails}
                setIsModalOpen={setIsModalOpen}
                isModalOpen={isModalOpen}
                value={selectedObstacleIndex}
            />
            : null}
        </Paper>
    );
}
