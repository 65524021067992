import React, { useEffect, useState, useMemo } from 'react'
import { Box, Button, fabClasses, Grid, Modal, Paper, Slider, Stack, Tooltip, Typography } from '@mui/material'
import { fontSize, styled } from "@mui/system";
import CloseButton from '../../tools/CloseButton'
import CircularLoader from "../../tools/CircularLoader";
import { IconComponent } from '@zippeditoolsjs/zippedi-icons';
import ObstacleModal from './ObstacleModal';

const VerticalThumbSlider = styled(Slider)({
  height: 0,
  "& .MuiSlider-thumb": {
    width: "4px",
    height: "25px",
    borderRadius: '2px',
    backgroundColor: '#1a242d',
    "&:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: 'none',
    },
  },

  "& .MuiSlider-track": {
    display: 'none',
  },

  "&::after": {
    content: '""',
    position: "absolute",
    top: "-30px",
    left: "50%",
    transform: "translateX(-50%)",
    width: "20px",
    height: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

export default function NavigationTimeline(props) {
  const {
    t,
    storeMap,
    obstacles,
    setPoses,
    setObstacles,
    sidenavRef,
    getObstacleThumbnails,
    isLoadingObstacleThumbnails,
    obstacleThumbnails
  } = props;

  const [maxValue, setMaxValue] = useState(30);
  const [value, setValue] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [obstacleIndex, setObstacleIndex] = useState(null);
  
  const points = useMemo(() => {
    if (!storeMap?.task_table_info || !storeMap?.map_info?.obstacles) return [];
    
    const posesWithType = storeMap.task_table_info.map(pose => ({
      ...pose,
      type: "pose",
      unifiedTimestamp: Date.parse(pose.timestamp),
    }));

    const obstaclesWithType = storeMap.map_info.obstacles.map(obstacle => ({
      ...obstacle,
      type: "obstacle",
      unifiedTimestamp: Date.parse(obstacle.timestamp),
    }));
    
    const mergedList = [...posesWithType, ...obstaclesWithType].sort(
      (a, b) => a.unifiedTimestamp - b.unifiedTimestamp
    );
    
    return mergedList;
  }, [storeMap]);

  useEffect(() => {
    setPoses([]);
    setObstacles([]);

    if (points.length > 0) {
      setMaxValue(points.length - 1);
    }
  }, [points]);

  const handleModalOpen = (index, obstacle) => {
    if (obstacleIndex !== index) {
      handleChange(null, index, obstacle)
      setIsModalOpen(true)
    }
  };

  const handleChange = (event, newValue, point) => {
    let tempUnifiedTimestamp = null;
    let newValueIndex = null;
    let filteredPoints;
    if(point["unifiedTimestamp"] === undefined){
      setObstacleIndex(newValue);
      tempUnifiedTimestamp =  Date.parse(point.timestamp)
      newValueIndex = points.findIndex(p => p.unifiedTimestamp === tempUnifiedTimestamp && p.type === "obstacle")
    } else if (point["type"] !== undefined && point["type"] === "obstacle") {
      const obstaclesWithType = storeMap.map_info.obstacles.map(obstacle => ({
        ...obstacle,
        unifiedTimestamp: Date.parse(obstacle.timestamp),
      }));
      const newIndex = obstaclesWithType.findIndex(o => o.unifiedTimestamp === points[newValue].unifiedTimestamp)
      setObstacleIndex(newIndex);
    }

    if (newValueIndex !== null) {
      filteredPoints = points.slice(0, newValueIndex + 1);
      setValue(newValueIndex);
    } else {
      filteredPoints = points.slice(0, newValue + 1);
      setValue(newValue);
    }
    
    const filteredPoses = filteredPoints.filter(p => p.type === "pose");
    const filteredObstacles = filteredPoints.filter(p => p.type === "obstacle");
    
    setPoses(filteredPoses);
    setObstacles(filteredObstacles);
  };

  return (
    <Paper ref={sidenavRef} sx={{ height: '100%' }}>
      <div style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", display: "flex" }}>
        {Array.isArray(points) &&
          points.map((item, index) => {
            const length = points?.length;
            const normalizedX = (index / length) * 100;
          
            let isTooltipOpen = index === value;
            if (isModalOpen){
              isTooltipOpen = false;
            } 

            return (
              (item.type === "obstacle" ?
                <div
                  onClick={() => handleModalOpen(index, item)}
                  style={{
                    position: "absolute",
                    left: `${normalizedX}%`,
                    minWidth: "1px",
                    height: "100%",
                    backgroundColor: item.permanent ? "#9d03fd" : "#fd6303",
                    padding: 0,
                    zIndex: 100,
                    cursor: 'pointer',
                  }}
                >
                  <Tooltip
                    title={<IconComponent iconName='warning-outline' sx={{fontSize: '60px'}}/>}
                    key={`${index}`}
                    placement="top"
                    open={isTooltipOpen}
                    arrow
                    slotProps={{
                      popper: { sx: { cursor: "pointer" } }
                    }}
                    >
                    <div
                      key={index}
                      style={{
                        position: "absolute",
                        left: `${normalizedX}%`,
                        width: "1px",
                        height: "100%",
                        backgroundColor: item.permanent ? "#9d03fd" : "#fd6303",
                        zIndex: 100,
                        pointerEvents: "auto"
                      }}
                    />
                  </Tooltip>
                </div>
                :
                null
              )
            );
          })}
      </div>
      <Grid sx={{ width: '100%' }}>
        <Stack spacing={2} direction="row" sx={{ alignItems: 'center', mb: 1 }}>
          <VerticalThumbSlider
            min={0}
            max={maxValue}
            value={value}
            valueLabelDisplay={points[value].type === "pose" || isModalOpen ? 'on' : 'off'}
            valueLabelFormat={(val) => points[val]?.timestamp.split('T')[1] || val}
            onChange={(event,newValue) => handleChange(event, newValue, points[newValue])}
          />
        </Stack>
      </Grid>

      {isModalOpen && obstacles && (obstacleIndex !== null) ? 
      <ObstacleModal
        t={t}
        storeMap={storeMap}
        handleChange={handleChange}
        getObstacleThumbnails={getObstacleThumbnails}
        isLoadingObstacleThumbnails={isLoadingObstacleThumbnails}
        obstacleThumbnails={obstacleThumbnails}
        setIsModalOpen={setIsModalOpen}
        isModalOpen={isModalOpen}
        value={obstacleIndex}
      />
      : null}
    </Paper>
  )
};