import {
	Grid,
	LinearProgress,
	Paper,
	Typography,
} from "@mui/material";
import { styled } from '@mui/material/styles';
import { format } from 'date-fns';
import { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { isEmptyOrUndefined, Selector } from "@zippeditoolsjs/blocks";

// My components
import { LocalStorageService } from 'core/services/localStorage';
import { useTranslation } from "react-i18next";

import DatePicker from "../../tools/DatePicker";
import InfiniteSelector from "../../tools/InfiniteSelector";
import PageLink from '../../tools/PageLink';

import { DigitalShelfContext } from '../../../contexts/DigitalShelf';
import ShelfSearch from '../../../redux/containers/features/digitalShelf/ShelfSearch';
import { HorizontalSelectorAisles } from "./HorizontalSelectorAisles";
import { HorizontalSelectorLandmarks } from "./HorizontalSelectorLandmarks";

import { checkFormat } from '../../utils/converters';

const DEFAULT_FLOOR = { name: 'Floor 1', value: 1 };
const PIXELS_TO_CENTER_LANDMARK_DELIMITATION = 7;
const CANVAS_WIDTH = 1000;

export default function WallSelector(props) {
	const {
		getUserStores,
		getWallsAisles,
		getWallImage,
		getLandmarks,
		getAllLandmark,
		getCoords,
		isLoadingUserStores,
		isLoadingAislesData,
		isLoadingCoordsInfo,
		isLoadingWallLandmarks,
		aislesData,
		userStores,
		coordsInfo,
		selectedClient,
		setWallLandmarks,
		wallLandmarks,
		linkReceived,
		setProductState,
		setLinkState,
		getAislePromotions,
		setAisleLandmarkState,
		getScannedBaysInfo,

		getUserSuppliers,
		suppliers,
		isLoadingSuppliers,

		storeMaps,
		getStoreMaps,
		isLoadingMaps,

		clientFormats,
		getStoresFloor,
		storesFloor,
		isStoreFloorLoading,

		getStoreAvailableSessions,
		storeAvailableSessions,
		isStoreAvailableSessionsLoading,
		setSnackAlert,
	} = props;
	const { t } = useTranslation();
	const [supplier, setSupplierState] = useState(null);

	const [coordsState, setCoordsState] = useState(null);
	const [aislesList, setAislesListState] = useState(null);
	const [linearNames, setLinearNamesState] = useState(null);

	const [selectedCategories, setCategoryState] = useState(null);
	const [categoryFilters, setCategoryFilters] = useState([]);
	const [supplierFilters, setSupplierFilters] = useState(suppliers);

	const [floorOptions, setFloorOptions] = useState([]);

	const [availableDays, setAvailableDays] = useState(new Set());

	// Context
	const {
		selectedStore,
		setSelectedStore,
		dateState,
		setDateState,
		selectedAisle,
		setAisleState,
		setProductPosPercent,
		selectedLandmark,
		setLandmarkState,
		productSearched,
		setProductSearched,
		selectedFloor,
		setSelectedFloor,
		baysDataWithCounts,
		setBaysDataWithCounts
	} = useContext(DigitalShelfContext);

	const handleSelectStore = useCallback((store) => {
		const localStorageService = new LocalStorageService();
		localStorageService.save(localStorageService.paths.dsh_store, store);
		setSelectedStore(store);
		getStoresFloor(store.store_code);
	}, [getStoresFloor]);

	const handleSelectFloor = useCallback((floor) => {
		const localStorageService = new LocalStorageService();
		localStorageService.save(localStorageService.paths.dsh_store_floor, floor);
		setSelectedFloor(floor);
	}, []);

	const handleSelectSupplier = useCallback((newSupplier) => {
		setSupplierState(newSupplier);
	}, []);

	const memoizedUserStores = useMemo(() => {
		return userStores?.sort((a, b) => a.chain_name.localeCompare(b.chain_name)) || [];
	}, [userStores]);

	const fetchWallData = async () => {
		setWallLandmarks([]);
		setLandmarkState({});
		getLandmarks(selectedStore['store_code'], selectedAisle['code'], selectedAisle['session']);
		getScannedBaysInfo(selectedStore['store_code'], selectedAisle['session'], selectedAisle['code']);
		await getWallImage(selectedStore['store_code'], supplier?.id, selectedAisle['session'], selectedAisle['code']);
		getCoords(selectedStore.store_id, supplier?.id || selectedClient?.supplier_id, selectedAisle['session'], selectedAisle['code'], selectedClient?.client_id);
		getAislePromotions(selectedStore.store_code, supplier?.id, selectedAisle.code, selectedAisle.session, selectedClient?.client_id);
	}

	const handleSelectCategory = (newCategory) => {
		if (!isEmptyOrUndefined(newCategory, 'object')) {
			setCategoryState(newCategory);
			const aislesList = aislesData['aisles_list'];
			if (aislesList?.length) {
				if (newCategory['category_name'] === 'ALL') {
					setAislesListState(aislesList);
					setSupplierFilters(suppliers);
				} else {
					const filteredAisles = [];
					aislesList.forEach(element => {
						if (element['category_name'].includes(newCategory['category_name'])) {
							filteredAisles.push(element)
						}
					});
					setAislesListState(filteredAisles);

					const categories_supplier = aislesData?.['categories_supplier']?.[newCategory.category_name] ?? [];
					const newSupplierFilter = categories_supplier.map(supplier_id => suppliers.find(s => s.id === supplier_id)).filter(Boolean);
					setSupplierFilters(newSupplierFilter);
				}
			}
		}
	}

	const sortAislesByName = () => {
		let sortedAisles = [...aislesList].sort((a, b) => {
			return a.code.localeCompare(b.code, undefined, {
				numeric: true,
				sensitivity: 'base'
			})
		})
		setAislesListState(sortedAisles);
	}

	const sortAislesByAlerts = () => {
		let sortedAisles = [...aislesList].sort((a, b) => {
			if (a['alerts'][0] < b['alerts'][0])
				return 1;
			return -1;
		})
		setAislesListState(sortedAisles);
	}

	const sortLandmarksByName = () => {
		let sortedLandmarks = [...baysDataWithCounts].sort((a, b) => {
			return a.display_name.localeCompare(b.display_name, undefined, {
				numeric: true,
				sensitivity: 'base'
			})
		})
		setBaysDataWithCounts(sortedLandmarks);
	}

	const sortLandmarksByAlerts = () => {
		let sortedLandmarks = [...baysDataWithCounts].sort((a, b) => {
			if (a.lInfo.alertCount < b.lInfo.alertCount)
				return 1;
			return -1;
		})
		setBaysDataWithCounts(sortedLandmarks);
	}

	const countLabelTotalAndAlerts = (value) => {
		let info = {
			alertCount: 0,
			total: 0
		}
		if (coordsInfo && coordsInfo.coords && Object.keys(coordsInfo.coords)) {
			coordsInfo.coords.forEach(label => {
				if (label.landmark === value) {
					if (label.stockout) {
						info.alertCount++;
					}
					info.total++;
				}
			})
		}
		return info;
	}

	const set_landmark = (coord, landmarkInfo, landmark_sequence, x_middle, next_landmark_position, is_last_scanned_landmark) => {

		if (landmarkInfo[coord['landmark']]) {
			// set position for the landmark's begining (landmark_postion) and ending (next_landmark_position) that are not in scanned_landmarks
			if ((!coord['landmark_label'] && !coord['promotion']) || is_last_scanned_landmark) {
				landmarkInfo[coord['landmark']].landmark_position = Math.min(x_middle, landmarkInfo[coord['landmark']].landmark_position)
				landmarkInfo[coord['landmark']].next_landmark_position = Math.max(next_landmark_position, landmarkInfo[coord['landmark']].next_landmark_position)
				landmarkInfo[coord['landmark']].pixel_x = landmarkInfo[coord['landmark']].landmark_position
			} else { // set position for the landmark's begining (landmark_postion) and ending (next_landmark_position) in scanned_landmarks
				landmarkInfo[coord['landmark']].landmark_position = x_middle
				landmarkInfo[coord['landmark']].next_landmark_position = next_landmark_position
				landmarkInfo[coord['landmark']].pixel_x = x_middle
			}

			// if the item is not a landmark or promotion, add it to the total count of products, stock out, price difference and spread to fill (if it applies)
			// the count increases in total_in_stock if the item doesn't have stock out
			if (!coord['landmark_label'] && !coord['promotion']) {
				landmarkInfo[coord['landmark']].total++;
				landmarkInfo[coord['landmark']].total_in_stock = coord.stockout ? landmarkInfo[coord['landmark']].total_in_stock : landmarkInfo[coord['landmark']].total_in_stock + 1;
				landmarkInfo[coord['landmark']].total_stock_out = coord.stockout ? landmarkInfo[coord['landmark']].total_stock_out + 1 : landmarkInfo[coord['landmark']].total_stock_out;
				landmarkInfo[coord['landmark']].total_price_difference = coord.price_difference ? landmarkInfo[coord['landmark']].total_price_difference + 1 : landmarkInfo[coord['landmark']].total_price_difference;
				landmarkInfo[coord['landmark']].total_spread_to_fill = coord.spread_to_fill ? landmarkInfo[coord['landmark']].total_spread_to_fill + 1 : landmarkInfo[coord['landmark']].total_spread_to_fill;
				landmarkInfo[coord['landmark']].total_extra_label = coord.extra_label ? landmarkInfo[coord['landmark']].total_extra_label + 1 : landmarkInfo[coord['landmark']].total_extra_label;
			}
		} else if (coord['landmark']) { // landmarkInfo initialization
			landmarkInfo[coord['landmark']] = {
				session: coord['session'],
				aisle: coord['aisle'],
				landmark: coord['landmark'],
				pixel_x: x_middle,
				pixel_z: 1,

				total: coord['landmark_label'] || coord['promotion'] ? 0 : 1,
				total_in_stock: coord.stockout || coord['landmark_label'] || coord['promotion'] ? 0 : 1,
				total_stock_out: coord.stockout ? 1 : 0,
				total_price_difference: coord.price_difference ? 1 : 0,
				total_spread_to_fill: coord.spread_to_fill ? 1 : 0,
				total_extra_label: coord.extra_label ? 1 : 0,
				landmark_position: x_middle,
				next_landmark_position: next_landmark_position
			}
			if (coord['landmark']) {
				landmark_sequence.push(coord['landmark'])
			}
		}
	}

	const handleSearchProduct = (product) => {
		if (product?.description !== productSearched?.text) setProductSearched(product);
		const coords = coordsInfo.coords;
		const products = coords?.filter(coord => (coord.item === product.item && coord.ean === product.ean)) ?? [];
		setProductState(products.length > 0 ? products[0] : {});
	}

	const handleIsDisabledDates = (date) => {
		const formattedDate = format(date, 'yyyy-MM-dd');
		return !availableDays.has(formattedDate);
	}

	useEffect(() => {
		if (!isStoreFloorLoading && storesFloor && selectedStore?.store_code) {
			const newFloors = (storesFloor[selectedStore.store_code] ?? []).map(floorNumber => { return { name: `${t('cws_app.general.floor', 'Floor')} ${floorNumber}`, value: floorNumber } });
			setFloorOptions(newFloors);
			if (newFloors.length === 1) {
				handleSelectFloor(newFloors[0]);
			}
		}
	}, [isStoreFloorLoading, storesFloor]);

	useEffect(() => {
		if (suppliers?.length > 0) {
			setSupplierState(null);
			setSupplierFilters(suppliers);
		}
	}, [suppliers]);

	useEffect(() => {
		if (linkReceived?.selectedStore || linkReceived?.storeName) {
			const linkProduct = linkReceived['product'];
			const linkDate = linkReceived['date'];
			const linkSelectedStore = linkReceived['selectedStore'];
			const linkFloor = linkReceived['selectedFloor'];
			const linkFloorValue = linkReceived['floorValue'];
			const linkSelectedCategory = linkReceived['selectedCategory'];

			setDateState(new Date(linkDate));
			if (linkSelectedStore) {
				setSelectedStore(linkSelectedStore);
			}
			if (linkFloor) {
				handleSelectFloor(linkFloor);
			} else if (linkFloorValue && floorOptions?.length) {
				handleSelectFloor({ name: `Floor ${linkFloorValue}`, value: linkFloorValue });
			} else if (!floorOptions?.length) {
			} else {
				handleSelectFloor(DEFAULT_FLOOR);
			}
			setCategoryState(linkSelectedCategory);
			setProductState(linkProduct);
		}
	}, [linkReceived]);

	useEffect(() => {
		if (selectedAisle && coordsInfo && Object.keys(coordsInfo)) {
			let newWallLandmarks = [];
			if (wallLandmarks && wallLandmarks.length) {
				const uniqueLandmarks = new Map();

				wallLandmarks.forEach(l => {
					if (!uniqueLandmarks.has(l.landmark)) {
						let lInfo = countLabelTotalAndAlerts(l.landmark);
						uniqueLandmarks.set(l.landmark, { ...l, lInfo });
					}
				});

				newWallLandmarks = Array.from(uniqueLandmarks.values());
				setCoordsState(newWallLandmarks);
			}
			else if (wallLandmarks && !wallLandmarks.length && coordsInfo.coords && Object.keys(coordsInfo.coords)) {
				let newLandmarks = [];
				let seenLands = [];
				const landmarkInfo = {};
				const landmark_sequence = [];
				let isLastScannedLandmark = false;
				let minBBoxY = Infinity;
				for (let i = 0; i < coordsInfo.coords.length; i++) {
					const coords = coordsInfo.coords;
					const coord = coordsInfo.coords[i];
					if (!seenLands.includes(coord.landmark) && (selectedAisle.code === coord.aisle)) {
						newLandmarks.push({ landmark: coord.landmark, session: coord.session, aisle: coord.aisle, })
						seenLands.push(coord.landmark)
					}

					const x_middle = (coord['facing_top_left_x'] + (coord['facing_bottom_right_x'] - coord['facing_top_left_x']) / 2);
					minBBoxY = Math.min(minBBoxY, coord['facing_top_left_y']);
					let next_landmark_position = x_middle
					if (coords[i + 1] && coord['landmark_label'] && coords[i + 1]['landmark_label']) {
						next_landmark_position = (coords[i + 1]['facing_top_left_x'] + (coords[i + 1]['facing_bottom_right_x'] - coords[i + 1]['facing_top_left_x']) / 2);
					} else if (wallLandmarks.length && coord['landmark'] === wallLandmarks[wallLandmarks.length - 1]['landmark']) {
						isLastScannedLandmark = true
					}
					set_landmark(coord, landmarkInfo, landmark_sequence, x_middle + PIXELS_TO_CENTER_LANDMARK_DELIMITATION, next_landmark_position + PIXELS_TO_CENTER_LANDMARK_DELIMITATION, isLastScannedLandmark);
				}
				newLandmarks.forEach(l => {
					let lInfo = countLabelTotalAndAlerts(l.landmark);
					newWallLandmarks.push({ ...l, lInfo });
				})
				let newWallLandmarksInfo = Object.values(landmarkInfo);
				if (newWallLandmarksInfo.length && selectedAisle.code === newWallLandmarksInfo[0].aisle) {
					newWallLandmarksInfo = newWallLandmarksInfo.map(l => ({ ...l, box_pixel_z: minBBoxY }));
					newWallLandmarksInfo = newWallLandmarksInfo.sort((a, b) => a.landmark.localeCompare(b.landmark, undefined, { numeric: true, sensitivity: 'base' }));
					setWallLandmarks(newWallLandmarksInfo);
				}
				setCoordsState(newWallLandmarks);
			}
		}
	}, [selectedAisle, wallLandmarks, coordsInfo]);

	useEffect(() => {
		if (productSearched && Object.keys(productSearched).length > 0
			&& aislesData && Object.keys(aislesData).length > 0
		) {
			let productAisle = aislesData.aisles_list.find(a => a.code === productSearched.aisle);
			if (productAisle?.code && productAisle.code !== selectedAisle?.code) {
				setAisleState(productAisle);
			}
		}
	}, [productSearched]);

	useEffect(() => {
		let landmarksDataTemp = {};
		let returnList = [];
		if (coordsInfo?.coords?.length > 0 && (productSearched?.ean || productSearched?.item)) {
			handleSearchProduct(productSearched);
		}
		if (wallLandmarks?.length > 0) {
			wallLandmarks.forEach(l => {
				if (l.landmark) {
					landmarksDataTemp[l.landmark] = { total: 0, stock: 0, zero_stock: 0, stockout: 0, spread_to_fill: 0, price_difference: 0, extra_label: 0 };
				}
			});
			if (coordsInfo?.coords?.length > 0) {
				coordsInfo.coords.forEach(coord => {
					if (coord?.landmark && landmarksDataTemp.hasOwnProperty(coord.landmark)) {
						landmarksDataTemp[coord.landmark].total++
						if (coord.spread_to_fill) landmarksDataTemp[coord.landmark].spread_to_fill++;
						else if (coord.stockout) landmarksDataTemp[coord.landmark].stockout++;
						else landmarksDataTemp[coord.landmark].stock++;

						if (coord.extra_label) landmarksDataTemp[coord.landmark].extra_label++;
						if (coord.price_difference) landmarksDataTemp[coord.landmark].price_difference++;
					}
				});
			}
			wallLandmarks.forEach(l => returnList.push({ ...l, 'data': landmarksDataTemp[l.landmark] }));
		}
		setAisleLandmarkState(returnList);
	}, [coordsInfo]);

	useEffect(() => {
		if (selectedStore
			&& selectedClient && Object.keys(selectedClient).length > 0
			&& selectedAisle && Object.keys(selectedAisle).length > 0
			&& aislesData && Object.keys(aislesData).length > 0) {
			fetchWallData();
			setProductPosPercent(null);
			setProductState({});
		}
	}, [selectedAisle]);

	useEffect(() => {
		if (selectedClient?.supplier_id) {
			setSupplierState({ name: selectedClient.name, id: selectedClient.supplier_id });
		} else {
			setSupplierState(null);
		}

		if (!isEmptyOrUndefined(selectedStore, 'object')) {
			setProductPosPercent(null);
			setProductState({});
			setProductSearched(null);

			if (floorOptions?.length > 1) {
				handleSelectFloor(null);
			}

			if (selectedClient?.client_id && selectedStore?.chain_name) {
				getUserSuppliers(selectedClient.client_id, [selectedStore.chain_name]);
			}

			if (!isStoreFloorLoading && selectedStore?.store_code) {
				getStoresFloor(selectedStore.store_code);
			}
		}
	}, [selectedStore, selectedClient]);

	useEffect(() => {
		if (!isStoreAvailableSessionsLoading && !isEmptyOrUndefined(selectedStore, 'object')) {
			const floorValue = selectedFloor ? selectedFloor.value : DEFAULT_FLOOR.value;
			getStoreAvailableSessions(selectedStore.store_code, floorValue);
		}
	}, [selectedStore, selectedFloor]);

	useEffect(() => {
		if (!isStoreAvailableSessionsLoading && Array.isArray(storeAvailableSessions) && storeAvailableSessions.length > 0) {
			try {
				const newAvailableDays = new Set(storeAvailableSessions);
				setAvailableDays(newAvailableDays);

				const sortedSessions = [...storeAvailableSessions].sort();
				const lastAvailableSession = sortedSessions[sortedSessions.length - 1];
				const newDate = lastAvailableSession ? new Date(`${lastAvailableSession} 00:00:00`) : new Date();

				const today = new Date();
				const isNotToday = !(newDate.getDate() === today.getDate() &&
					newDate.getMonth() === today.getMonth() &&
					newDate.getFullYear() === today.getFullYear());

				if (isNotToday) {
					setSnackAlert({
						open: true,
						message: t('cws_app.General.last_session_not_today',
							'The last available session is not today, showing {{date}}',
							{ date: format(newDate, checkFormat(clientFormats?.date_format ?? 'yyyy/MM/dd')) }),
						severity: 'info'
					});
				}

				setDateState(newDate);
			} catch (error) {
				console.error('Error processing available sessions:', error);
			}
		}
	}, [storeAvailableSessions, isStoreAvailableSessionsLoading]);

	useEffect(() => {
		if (selectedStore &&
			dateState &&
			(selectedFloor || floorOptions.length <= 1) &&
			!isStoreAvailableSessionsLoading &&
			storeAvailableSessions?.length) {

			let timeoutId = null;
			const controller = new AbortController();

			const debouncedRequest = () => {
				if (timeoutId) clearTimeout(timeoutId);

				timeoutId = setTimeout(() => {
					const floorValue = selectedFloor ? selectedFloor.value : DEFAULT_FLOOR.value;
					if (!storeAvailableSessions.includes(format(dateState, 'yyyy-MM-dd'))) return;

					getWallsAisles(
						selectedStore['store_code'],
						supplier?.id,
						true,
						format(dateState, 'yyyy/MM/dd'),
						floorValue,
						selectedClient?.client_id
					);

					if (!isLoadingMaps && (!storeMaps?.length || storeMaps[0].store_id !== selectedStore.store_id)) {
						const parsedDate = format(dateState, 'yyyy-MM-dd');
						getStoreMaps({
							date: parsedDate,
							fixed_width: CANVAS_WIDTH,
							store_id: selectedStore.store_id,
							client_id: selectedClient.client_id,
							floor_number: floorValue,
						});
					}
				}, 300);
			};

			debouncedRequest();

			return () => {
				if (timeoutId) clearTimeout(timeoutId);
				controller.abort();
			};
		}
	}, [selectedStore, selectedFloor, supplier, dateState, storeAvailableSessions]);

	useEffect(() => {
		if (!isLoadingUserStores && userStores && userStores.length > 0 && linkReceived?.storeName) {
			const linkStoreName = linkReceived['storeName'];
			const linkSelectedStore = userStores.find(s => s.parsed_name === linkStoreName);
			setSelectedStore(linkSelectedStore);
		} else if (!isLoadingUserStores && userStores && userStores.length > 0) {
			const localStorageService = new LocalStorageService();
			if (selectedStore === null) {
				let savedStoreSelection = localStorageService.get(localStorageService.paths.dsh_store);
				let store_in_list = userStores.find(s => s.store_id === savedStoreSelection?.store_id);

				if (savedStoreSelection && Object.keys(savedStoreSelection)
					&& store_in_list !== undefined && Object.keys(store_in_list).length > 0) {
					setSelectedStore(savedStoreSelection);
				}
				else if (savedStoreSelection === null && userStores && userStores.length > 0) {
					setSelectedStore(userStores[0]);
				}
			} else {
				let store_in_list = userStores.find(s => s.store_id === selectedStore?.store_id);
				if (store_in_list === undefined && userStores && userStores.length > 0) {
					setSelectedStore(userStores[0]);
				}
			}
		}
	}, [isLoadingUserStores, userStores]);

	useEffect(() => {
		if (!isStoreFloorLoading && floorOptions?.length > 1 && linkReceived?.floorValue) {
			const linkSelectedFloor = floorOptions.find(f => f.value === linkReceived.floorValue);
			handleSelectFloor(linkSelectedFloor);
		} else if (!isStoreFloorLoading && floorOptions?.length > 1 && selectedFloor === null) {
			const localStorageService = new LocalStorageService();
			let savedFloorSelection = localStorageService.get(localStorageService.paths.dsh_store_floor);
			if (savedFloorSelection && Object.keys(savedFloorSelection) && savedFloorSelection?.store_code === selectedStore?.store_code) {
				handleSelectFloor(savedFloorSelection);
			} else {
				handleSelectFloor(floorOptions[0]);
			}
		}
	}, [isStoreFloorLoading, floorOptions]);

	useEffect(() => {
		if (selectedStore && !isLoadingAislesData && linkReceived?.selectedAisle && aislesList) {
			const linkSelectedAisle = linkReceived['selectedAisle'];
			setAisleState(linkSelectedAisle);
			setLinkState({});
		} else if (selectedStore && !isLoadingAislesData && linkReceived?.aisleName && aislesList) {
			const linkAisleName = linkReceived['aisleName'];
			const linkSelectedAisle = aislesList.find(a => a.code === linkAisleName);
			setAisleState(linkSelectedAisle);
			setLinkState({});
		} else if (selectedStore && !isLoadingAislesData && aislesList && aislesList.length > 0 && selectedAisle === null) {
			const localStorageService = new LocalStorageService();
			let savedStoreSelection = localStorageService.get(localStorageService.paths.dsh_store);
			let store_in_list = aislesList.find(s => s.store_id === savedStoreSelection?.store_id);
			if (savedStoreSelection === null && store_in_list !== undefined && Object.keys(store_in_list).length > 0) {
				setAisleState(aislesList[0]);
				localStorageService.save(localStorageService.paths.dsh_store, selectedStore);
			}
		}
	}, [isLoadingAislesData, selectedStore, aislesList]);

	useEffect(() => {
		if (selectedClient) {
			getUserStores(selectedClient.client_id);
			setSupplierState(selectedClient?.supplier_id ? { name: selectedClient.name, id: selectedClient.supplier_id } : null);
		}
	}, [getUserStores, selectedClient]);

	useEffect(() => {
		if (selectedStore) {
			if (aislesData && Object.keys(aislesData).length > 0) {
				setAislesListState(aislesData.aisles_list);
				const newCategoryFilters = aislesData.categories_filter.map(c => ({ category_name: c }));
				setCategoryFilters(newCategoryFilters);

				if (!selectedCategories || !newCategoryFilters.some(c => c.category_name === selectedCategories.category_name)) {
					setCategoryState({ category_name: 'ALL' });
				}

				getAllLandmark(selectedStore.store_code, supplier?.id, aislesData.session);
				if (aislesData.client_linear_names) {
					setLinearNamesState(aislesData.client_linear_names);
				}
			} else {
				setCategoryFilters([]);
				setCategoryState(null);
			}
		}
	}, [aislesData]);

	return (
		<Grid item container
			direction={'row'}
			spacing={{ xs: 1, md: 1 }}
		>
			{/* Store selector */}
			<Grid item xs={12} md={4} lg={4} px={1}>
				<StyledPaper StyledPaper elevation={0} sx={{ px: 1, py: 0.2 }}>
					<InfiniteSelector
						inputLabel={t('cws_app.general.sel_store')}
						options={memoizedUserStores}
						inputVariant='standard'
						inputSelected={selectedStore}
						handleSelect={handleSelectStore}
						objectName={'parsed_name'}
						objectId={'store_id'}
						groupBy={'chain_name'}
						isLoading={isLoadingUserStores}
					/>
					{isLoadingUserStores && <LinearProgress sx={{ width: '100%', mx: 'auto', top: '-4px' }} color="secondary" />}
				</StyledPaper>
			</Grid>
			{/* Store Floor selector */}
			{
				floorOptions.length > 1 &&
				<Grid item xs={12} md={2} lg={2} px={1}>
					<Selector
						label={t("cws_app.general.sel_floor")}
						options={floorOptions}
						name={'name'}
						loading={isStoreFloorLoading}
						value={selectedFloor}
						setValue={handleSelectFloor}
						disabled={isEmptyOrUndefined(selectedStore, 'object') || floorOptions.length === 0 || isStoreFloorLoading || isLoadingUserStores || isLoadingAislesData || isLoadingCoordsInfo || isLoadingWallLandmarks}
						disableClearable={false}
					/>
				</Grid>
			}
			{/* Date selector */}
			<Grid item xs={12} md={2} px={1}>
				<DatePicker
					date={dateState}
					setDate={setDateState}
					inputFormat={checkFormat(clientFormats?.date_format ?? 'yyyy/MM/dd')}
					shouldDisableDate={handleIsDisabledDates}
				/>
			</Grid>
			{/* Suppliers selector */}
			{
				!selectedClient?.supplier_id ?
					<Grid item xs={12} md={3} lg={3} px={1}>
						<Selector
							label={t("cws_app.general.select_supplier")}
							options={supplierFilters}
							name={'name'}
							loading={isLoadingSuppliers}
							value={supplier}
							setValue={handleSelectSupplier}
							disabled={isEmptyOrUndefined(selectedStore, 'object') || supplierFilters.length === 0 || isStoreFloorLoading || isLoadingSuppliers || isLoadingUserStores || isLoadingAislesData || isLoadingCoordsInfo || isLoadingWallLandmarks}
							disableClearable={false}
						/>
					</Grid>
					: null
			}
			{/* Category selector */}
			<Grid item xs={12} md={3} lg={3} px={1}>
				<Selector
					label={t("cws_app.general.sel_category")}
					disabled={isEmptyOrUndefined(categoryFilters, 'array') || isEmptyOrUndefined(selectedStore, 'object')}
					options={categoryFilters}
					name={'category_name'}
					loading={isLoadingAislesData}
					value={selectedCategories}
					setValue={handleSelectCategory}
					disableClearable={false}
				/>
			</Grid>
			{/* Search input */}
			<Grid item xs={12} md={6} px={1}>
				<ShelfSearch aislesList={aislesList} selectedStore={selectedStore} supplier={supplier?.id}
					selectedAisle={selectedAisle} product={productSearched} setProduct={handleSearchProduct}
					disabled={categoryFilters && Object.keys(categoryFilters).length > 0 ? false : true}
				/>
			</Grid>
			{/* Link button */}
			<Grid container item xs={'auto'} md={true} justifyContent={{ xs: "flex-start", md: "flex-end" }} mb='5px' alignItems='flex-end'>
				<PageLink
					currentState={{
						date: dateState,
						selectedStore: selectedStore,
						selectedFloor: selectedFloor,
						selectedCategory: selectedCategories,
						selectedAisle: selectedAisle,
					}}
					inputSize='small'
					view='/cpg/walls'
					text={t('cws_app.general.link', 'Link')}
				/>
			</Grid>
			{
				!selectedStore ?
					<Grid item xs={12} >
						<Paper elevation={0} sx={{ p: 2.5, }} >
							<Typography textAlign={'center'} >
								{t('cws_app.general.select_information_to_display', 'Select Information to Display')}
							</Typography>
						</Paper>
					</Grid>
					: selectedStore && !isLoadingAislesData && !aislesData?.aisles_list ?
						<Grid item xs={12}>
							<Paper elevation={0} sx={{ p: 2.5, }} >
								<Typography textAlign={'center'} >
									{t('cws_app.general.aisles_not_found',
										'Unable to display Digital Shelf based on your store and aisle selection.')}
								</Typography>
							</Paper>
						</Grid>
						:
						<Grid item container xs={12} spacing={1}>
							<Grid item xs={coordsState && (Object.keys(coordsState).length >= 1) ? 6 : 12} >
								<Paper elevation={0} sx={{ px: 1, }}>
									<HorizontalSelectorAisles
										selectedChain={selectedStore?.chain_name ?? 'General'}
										aislesList={aislesList}
										linearNames={linearNames}

										aislesData={aislesData}
										isLoadingAislesData={isLoadingAislesData}

										selectedAisle={selectedAisle}
										setAisleState={setAisleState}

										sortAislesByName={sortAislesByName}
										sortAislesByAlerts={sortAislesByAlerts}
									/>
								</Paper>
							</Grid>
							{!isLoadingAislesData && selectedAisle && coordsState && (Object.keys(coordsState).length >= 1)
								?
								<Grid item xs={6}>
									<Paper elevation={0} sx={{ px: 1, }}>
										<HorizontalSelectorLandmarks
											selectedStore={selectedStore}
											selectedLandmark={selectedLandmark}
											setLandmarkState={setLandmarkState}

											coordsState={coordsState}
											isLoadingWallLandmarks={isLoadingWallLandmarks}

											coordsInfo={coordsInfo}
											isLoadingCoordsInfo={isLoadingCoordsInfo}

											sortLandmarksByName={sortLandmarksByName}
											sortLandmarksByAlerts={sortLandmarksByAlerts}
										/>
									</Paper>
								</Grid>
								: null
							}
						</Grid>
			}
		</Grid >
	);
}

const StyledPaper = styled(props => {
	return (
		<Paper
			elevation={1}
			{...props}
		/>
	)
}
)(({ theme }) => {
	return ({
		width: '100%',
		background: theme.palette.type === 'light' ? 'white' : theme.palette.paper.main,
		'& .MuiPaper-root': {
			backgroundColor: theme.palette.type === 'light' ? theme.palette.primary.main : theme.palette.paper.main,
			color: 'white',
		},
		'& .MuiInputLabel-root': {
			color: theme.palette.type === 'light' ? 'rgba(0, 0, 0, 0.6)' : 'rgba(255, 255, 255, 0.6)',
		},
		'& .MuiOutlinedInput-root': {
			color: theme.palette.type === 'light' ? 'rgba(0, 0, 0, 0.6)' : 'rgba(255, 255, 255, 0.6)',
			'& fieldset': {
				borderColor: theme.palette.type === 'light' ? 'rgba(0, 0, 0, 0.6)' : 'rgba(255, 255, 255, 0.6)',
			},
		},
	})
}
);
