import React, { useState } from 'react'
import { Buffer } from 'buffer';
import { Box, Button, IconButton, Tooltip, Typography, useTheme } from '@mui/material'
import { IconComponent } from '@zippeditoolsjs/zippedi-icons'
import { useTranslation } from 'react-i18next';

export default function PageLink(props) {
	const {
		currentState,
		view,
		text = '',
		color = 'inherit',
		inputSize = 'medium',
		sx = {},
		disabled = false,
		firstTooltipTitle = null,
		secondTooltipTitle = null,
		iconButton = null,
		isRedirect = false,
		onlyText = false,
	} = props;
	const { t } = useTranslation();
	const theme = useTheme();
	const [open, setOpen] = useState(false)

	const handleLink = () => {
		setOpen(true);
		const state = JSON.stringify(currentState);
		const encodedState = Buffer.from(state, 'utf8').toString('base64');
		const link = `${window.location.origin}/share?state=${encodedState}&view=${view}`;
		// Redirect to a new browser tab or copy the link to the clipboard
		if (isRedirect) {
			window.open(link, "_blank")
		} else {
			navigator.clipboard.writeText(link);
			// To remove the second tooltip after 1 second.
			setTimeout(() => {
				setOpen(false);
			}, 1000);
		}
	};

	return (
		<Tooltip title={firstTooltipTitle || firstTooltipTitle === '' ? firstTooltipTitle : t('cws_app.general.copy_link')} placement='top'>
			<Box>
				<Tooltip title={secondTooltipTitle || secondTooltipTitle === '' ? secondTooltipTitle : t('cws_app.general.link_copied')} open={open} placement='top'>
					{iconButton ?
						<IconButton sx={sx} onClick={handleLink} disabled={disabled}>
							{iconButton}
						</IconButton>
						: onlyText ?
							<Typography onClick={handleLink} sx={[sx, { cursor: 'pointer', color: theme.palette.info.main, textDecoration: 'underline' }]}>{text}</Typography>
							:
							<Button
								onClick={handleLink}
								disabled={disabled}
								size={inputSize}
								variant='contained'
								sx={{backgroundColor: 'white',
									borderRadius: '20px',
									height: '40px',
									minWidth: '40px',
									padding: '0', 
									color: color,
									'&:hover': {
										backgroundColor: 'rgba(0, 0, 0, 0.1)' // Color al hacer hover
									}}}
							>
								<IconComponent
									iconName={'share-social-outline'}
									style={{ fontSize: '20px' }}
								/>
							</Button>
					}
				</Tooltip>
			</Box>
		</Tooltip>
	)
}
