import React from 'react'
import { Grid, Paper, Tooltip, Typography, Divider } from '@mui/material'
import CloseButton from '../../tools/CloseButton'
import CircularLoader from "../../tools/CircularLoader";

export default function SummaryCard(props) {
  const {
    t,
    storeMap,
    setIsSidenavOpen,
    sidenavRef,
    robotSession,
    sessionData,
    isLoadingSessionData,
  } = props;

  return (
    <Paper ref={sidenavRef} sx={{ position: 'relative', width: { xs: '100vh', md: '35%' }, height: 'auto', ml: { xs: 0, md: 1 }, mt: { xs: 1, md: 0 }, px: 2, overflow: 'auto' }}>
      {/* Header */}
      <Grid container item justifyContent='flex-end' alignItems='center' sx={{ position: 'absolute', right: '1%' }}>
        <CloseButton setIsOpen={setIsSidenavOpen} isColorbgAlwaysDark={false} />
      </Grid>
      {(isLoadingSessionData)?
        <Grid display='flex' alignItems='center' justifyContent='center' flexDirection='column' sx={{ height: '100%' }}>
          <Grid container item direction='column' justifyContent='center' alignItems='center' my={12}>
            <CircularLoader />
            <Typography>{t('cws_app.digital_store.loading_navigation_summary', 'Loading navigation summary')}</Typography>
          </Grid>
        </Grid>
        :
        <>
        {/* Session title */}
        <Grid container item justifyContent='start' mt={4}>
          <Typography variant='h6'>{t('cws_app.digital_store.session', 'Session')} {robotSession.split('-')[0].trim()}</Typography>
        </Grid>
        {/* Obstacles and general information */}
        <Grid container item direction='column' justifyContent='center' alignItems='center' my={1}>
          {/* Planned navigation start and arrival time */}
          <Grid container alignItems='flex-start'>
            <Grid container item xs={6}>
              <Typography color="textSecondary" style={{fontSize: '14px'}}>{t('cws_app.digital_store.planned_start_time', 'Planned start time')}</Typography>
            </Grid>
            <Grid container item xs={6}>
              <Typography style={{fontSize: '14px'}}>{sessionData.session_data[0].planned_for}</Typography>
            </Grid>
            <Grid container item xs={6}>
              <Typography color="textSecondary" style={{fontSize: '14px'}}>{t('cws_app.digital_store.planned_arrival_time', 'Planned arrival time')}</Typography>
            </Grid>
            <Grid container item xs={6}>
              <Typography style={{fontSize: '14px'}}>{sessionData.session_data[0].planned_arrival}</Typography>
            </Grid>
          </Grid>
          <Divider style={{ width: '-webkit-fill-available', marginTop: 8, marginBottom: 8}}/>
          {/* Day and permanent obstacles */}
          <Grid container item justifyContent='space-around' flex-direction='row'>
            <Grid container item xs={6} alignItems='flex-start' flexDirection='column'>
              <Typography variant='h6' style={{fontSize: '14px'}}>{t('cws_app.digital_store.day_obstacles', 'Day obstacles')}</Typography>
              <Divider style={{ backgroundColor: '#fd6303', width: '87%', height: '2px', marginTop: 6, marginBottom: 4}}/>
              <Typography variant='h4' style={{color: '#fd6303'}}>{storeMap.day_obstacles}</Typography>
            </Grid>
            <Grid container item xs={6} alignItems='flex-start' flexDirection='column'>
              <Typography variant='h6' style={{fontSize: '14px'}}>{t('cws_app.digital_store.constant_obstacles', 'Constant obstacles')}</Typography>
              <Divider style={{ backgroundColor: '#9d03fd', width: '87%', height: '2px', marginTop: 6, marginBottom: 4}}/>
              <Typography variant='h4' style={{color: '#9d03fd'}}>{storeMap.permanent_obstacles}</Typography>
            </Grid>
          </Grid>
          {/* Robot states */}
          <Grid container item alignItems='flex-start' flexDirection='column' mt={2}>
            <Grid container item alignItems='center' flexDirection='row'>
              <Divider style={{ borderStyle: 'dotted', borderColor: '#00ff00', borderWidth: '4px', width: '20%', borderBottom: '0px', marginTop: 6, marginBottom: 4, marginRight: 10}}/>
              <Typography variant='h6' style={{fontSize: '14px'}}>{t('cws_app.digital_store.navigation_mode', 'Navigation mode')}</Typography>
            </Grid>
            <Grid container item alignItems='center' flexDirection='row'>
              <Divider style={{ borderStyle: 'dotted', borderColor: '#0000ff', borderWidth: '4px', width: '20%', borderBottom: '0px', marginTop: 6, marginBottom: 4, marginRight: 10}}/>
              <Typography variant='h6' style={{fontSize: '14px'}}>{t('cws_app.digital_store.data_capture_mode', 'Data capture mode')}</Typography>
            </Grid>
            <Grid container item alignItems='center' flexDirection='row'>
              <Divider style={{ borderStyle: 'dotted', borderColor: '#ff0000', borderWidth: '4px', width: '20%', borderBottom: '0px', marginTop: 6, marginBottom: 4, marginRight: 10}}/>
              <Typography variant='h6' style={{fontSize: '14px'}}>{t('cws_app.digital_store.robot_disoriented', 'Robot disoriented')}</Typography>
            </Grid>
          </Grid>
          <Divider style={{ width: '-webkit-fill-available', marginTop: 8, marginBottom: 8}}/>
          {/* General information */}
          <Grid container alignItems='flex-start'>
            <Typography variant='h6' style={{fontSize: '14px'}}>{t('cws_app.digital_store.general_information', 'General information')}</Typography>
          </Grid>
          <Grid container alignItems='flex-start'>
            <Grid container item xs={6}>
              <Typography color="textSecondary" style={{fontSize: '14px'}}>{t('cws_app.digital_store.actual_start_time', 'Actual start time')}</Typography>
            </Grid>
            <Grid container item xs={6}>
              <Typography style={{fontSize: '14px'}}>{sessionData.session_data[0].nav_start}</Typography>
            </Grid>
            <Grid container item xs={6}>
              <Typography color="textSecondary" style={{fontSize: '14px'}}>{t('cws_app.digital_store.actual_arrival_time', 'Actual arrival time')}</Typography>
            </Grid>
            <Grid container item xs={6}>
              <Typography style={{fontSize: '14px'}}>{sessionData.session_data[0].nav_finish}</Typography>
            </Grid>
            <Grid container item xs={6}>
              <Typography color="textSecondary" style={{fontSize: '14px'}}>{t('cws_app.digital_store.total_navigation_time', 'Total navigation time')}</Typography>
            </Grid>
            <Grid container item xs={6}>
              <Typography style={{fontSize: '14px'}}>{sessionData.session_data[0].nav_time}</Typography>
            </Grid>
            <Grid container item xs={6}>
              <Typography color="textSecondary" style={{fontSize: '14px'}}>{t('cws_app.digital_store.planned_linears', 'Planned linears')}</Typography>
            </Grid>
            <Grid container item xs={6}>
              <Typography style={{fontSize: '14px'}}>{sessionData.aisles_data[0].total_active_linears}</Typography>
            </Grid>
            <Grid container item xs={6}>
              <Typography color="textSecondary" style={{fontSize: '14px'}}>{t('cws_app.digital_store.navigated_linears', 'Navigated linears')}</Typography>
            </Grid>
            <Grid container item xs={6}>
              <Typography style={{fontSize: '14px'}}>{sessionData.aisles_data[0].total_navigated_linears}</Typography>
            </Grid>
            <Grid container item xs={6} alignItems='center'>
              <Tooltip title={t('cws_app.digital_store.planned_vs_navigated', 'Planned linears vs navigated linears')} arrow>
                <Typography color="textSecondary" style={{fontSize: '14px'}}>{t('cws_app.digital_store.compliance', 'Compliance')}</Typography>
              </Tooltip>
            </Grid>
            <Grid container item xs={6}>
              <Typography style={{fontSize: '14px'}}>{Math.round((sessionData.aisles_data[0].total_navigated_linears/sessionData.aisles_data[0].total_active_linears) * 1000) / 10}%</Typography>
            </Grid>
          </Grid>
        </Grid>
        </>
      }
    </Paper>
  )
}