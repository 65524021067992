import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Popover, Tab, Tabs, Tooltip, Typography } from "@mui/material";
import { useTranslation } from 'react-i18next';
import { getConsolidatedTranslations, getChainMeaning } from 'core/utils/parsers';
import { format } from '../../utils/converters';
import { isSameDay } from 'date-fns';
import DatePicker from "../../tools/DatePicker";
import { replaceWhiteSpace, checkFormat } from "../../utils/converters";
import CircularLoader from "../../tools/CircularLoader";

import { isEmptyOrUndefined } from "@zippeditoolsjs/blocks";
import { IconComponent } from "@zippeditoolsjs/zippedi-icons";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

export default function DownloadSection(props) {
  const {
    displayedColumns,
    storeCategories,
    chain = '',
    filteredTaskData = [],
    isDataFiltered = false,

    client_id,
    signed_logo,
    supplier_id,

    inputDate = new Date(),
    inputTaskType,
    inputStoreId,
    inputStoreName,

    getDigitalStoreExcel,
    getDigitalStorePdf,
    getDigitalStoreInv,
    getExcelByDate,

    isExcelLoading,
    isPdfLoading,
    isInvLoading,
    isLoadingExcelByDate,

    linkReceived,

    filteredColumns = ['sub_category'],
    resumeBiData,
    subCategoriesByTask,
    linearAislesClientNames = {},
    aislesClientNames = {},
    robotSessions,
    selectedRobotIndex = 0,
    selectedCategory = {},
    clientFormats,
  } = props;
  const { t, i18n } = useTranslation();
  const [tab, setTab] = useState(0);
  // Popover states
  const [anchorEl, setAnchorEl] = useState(null);
  const openPopover = Boolean(anchorEl);
  const id = openPopover ? 'download-popover' : undefined;
  // Date Picker states
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  // Min date (3 months past) for the Date Picker
  const today = new Date();
  const todayMinDate = new Date();
  const minDate = todayMinDate.setMonth(todayMinDate.getMonth() - 3);

  const [isDownloading, setIsDownloading] = useState(false);

  // Lifecycle methods

  // Download the file automatically when it comes from a link
  useEffect(() => {
    if (linkReceived && filteredTaskData && filteredTaskData.length > 0 && !isDownloading) {
      switch (linkReceived?.download) {
        case 'inv':
          handleDownloadInv();
          setIsDownloading(true);
          break;
        case 'pdf':
          handleDownloadPdf();
          setIsDownloading(true);
          break;
        case 'excel':
          handleDownloadExcel();
          setIsDownloading(true);
          break;
        default:
          break;
      }
    }
  }, [linkReceived, filteredTaskData]);

  // Methods

  const getRobotName = () => {
    return robotSessions?.length > 1 ? `Robot ${selectedRobotIndex}` : null
  }

  const handleDownloadPdf = () => {
    // TODO: handle abort fetch when a fetch is in progress
    // if (pdfSubscription) { pdfSubscription.unsubscribe() }
    // filteredTaskData.length === taskData.length
    const canContinueDownloading = !isDataFiltered ? true : window.confirm(getConsolidatedTranslations(t, chain, 'sure_of_downloading_filtered_pdf'));
    if (canContinueDownloading) {

      let categoriesList = []
      if (isEmptyOrUndefined(selectedCategory, 'object')) {
        categoriesList = storeCategories
      } else {
        storeCategories.forEach(category => {
          if (category?.category_name === selectedCategory?.category_name) {
            categoriesList.push(category)
          }
        })
      }

      const selectedDate = isSameDay(new Date(inputDate.replaceAll('-', '/')), new Date()) ?
        format(new Date(), i18n.language, 'yyyy-MM-dd HH:mm:ss') :
        format(new Date(inputDate.replaceAll('-', '/')), i18n.language, 'yyyy-MM-dd 23:59:59'),

        r_type = inputTaskType,
        stats = resumeBiData ? resumeBiData.map(BIRow => { return { header_name: getConsolidatedTranslations(t, chain, BIRow?.info ?? BIRow?.key), info: BIRow.value } }) : null,
        reportColumns = displayedColumns ? displayedColumns.filter(row => !filteredColumns.includes(row)) : [],
        translatedReportColumns = reportColumns ? reportColumns.map(column => getChainMeaning(t, chain, column)) : null,
        categories = r_type === 'assortment' ? Object.assign({}, ...categoriesList.map((category) => ({ [category.value]: category.category_name }))) : null,
        subCategories = r_type === 'assortment' ? Object.assign({}, ...subCategoriesByTask.map((category) => ({ [category.value]: category.name }))) : null;

      const form = {
        r_type: r_type,
        client_id: client_id,
        store_id: inputStoreId,
        signed_logo: signed_logo !== '/' ? signed_logo : null,
        selected_date: selectedDate,
        robot_header: getRobotName(),
        report_title: getConsolidatedTranslations(t, chain, `report_${inputTaskType}`),
        report_columns_names: JSON.stringify(translatedReportColumns),
        aisles_client_names: JSON.stringify(linearAislesClientNames),
        report_columns: JSON.stringify(reportColumns),
        sub_categories: JSON.stringify(subCategories),
        table_info: JSON.stringify(filteredTaskData),
        categories: JSON.stringify(categories),
        stats: JSON.stringify(stats),
        // TODO: check following format
        language: i18n.language.split("-")[0],
      }
      if (filteredTaskData.length || r_type === 'robot_navigation' || r_type === 'robot_navigation_2_test') getDigitalStorePdf(form)
      setIsDownloading(false);


      // TODO: insert this alert in the slice
      // alert(getConsolidatedTranslations(t, chain, 'report_not_found'));
    } else {
      setIsDownloading(false);
    }
  }

  const canDownloadInv = (taskType) => {
    const validTaskTypes = ['price_difference', 'assortment'];
    const validChains = ['Jumbo', 'Santa Isabel'];
    // technical debt
    // If this scales to other stores, the following line should be refactored and rethought in a more complex logic
    return (validTaskTypes.includes(taskType) && validChains.includes(chain));
  }

  const handleDownloadInv = () => {

    const canContinueDownloading = !isDataFiltered ? true : window.confirm(getConsolidatedTranslations(t, chain, 'sure_of_downloading_filtered_inv'));
    if (canContinueDownloading) {

      const selectedDate = isSameDay(new Date(inputDate.replaceAll('-', '/')), new Date()) ?
        format(new Date(), i18n.language, 'yyyy-MM-dd HH:mm:ss') :
        format(new Date(inputDate.replaceAll('-', '/')), i18n.language, 'yyyy-MM-dd 23:59:59'),

        r_type = inputTaskType,
        stats = resumeBiData.map(BIRow => { return { header_name: getConsolidatedTranslations(t, chain, BIRow?.info ?? BIRow?.key), info: BIRow.value } }),
        reportColumns = displayedColumns ? displayedColumns.filter(row => !filteredColumns.includes(row)) : [],
        translatedReportColumns = reportColumns.map(column => getChainMeaning(t, chain, column));

      const form = {
        r_type: r_type,
        client_id: client_id,
        store_id: inputStoreId,
        signed_logo: signed_logo !== '/' ? signed_logo : null,
        selected_date: selectedDate,
        robot_header: getRobotName(),
        report_title: getConsolidatedTranslations(t, chain, `report_${inputTaskType}`),
        report_columns_names: JSON.stringify(translatedReportColumns),
        report_columns: JSON.stringify(reportColumns),
        table_info: JSON.stringify(filteredTaskData),
        stats: JSON.stringify(stats),
        language: i18n.language.split("-")[0],
      };

      if (filteredTaskData.length) getDigitalStoreInv(form)
      setIsDownloading(false);

      // TODO: handle when inv is not available
      // alert(getConsolidatedTranslations(t, chain, 'inv_not_found'));
    } else {
      setIsDownloading(false);
    }
  }

  const handleDownloadExcel = () => {
    const tableDownloadAvailableTypes = [
      "promotions",
      "adjustment",
      "assortment",
      "price_difference",
      "partial_stockout",
      "stockout",
      "missing_landmark",
      "OSA",
    ];
    const canDownload = tableDownloadAvailableTypes.includes(inputTaskType);
    const canContinueDownloading = !isDataFiltered ? true : window.confirm(getConsolidatedTranslations(t, chain, 'sure_of_downloading_filtered_excel'));
    if (canContinueDownloading && canDownload) {
      const reportsColumns = displayedColumns.filter(displayedColumn => !filteredColumns.includes(displayedColumn));

      let dataToDownload = filteredTaskData

      // Filter data by the selectedCategory
      if (!isEmptyOrUndefined(selectedCategory, 'object') && selectedCategory?.category_name !== 'All') {
        dataToDownload = filteredTaskData.filter(data => data?.main_category === selectedCategory?.category_name)
      }
      if (dataToDownload?.length > 0 && dataToDownload[0].main_category && !reportsColumns.includes('main_category')) {
        reportsColumns.push('main_category')
      }

      dataToDownload = dataToDownload.map(rowTaskData => reportsColumns.map(displayedColumn => {
        if (displayedColumn === 'aisle') {
          return 'aisle_name' in rowTaskData ? rowTaskData['aisle_name'] : rowTaskData['aisle']
        } else {
          return rowTaskData[displayedColumn]
        }

      }));

      // Translate aisle column
      if (reportsColumns.includes("aisle")) {
        const aisleIndex = reportsColumns.indexOf("aisle")
        dataToDownload.forEach(data => {
          const aislesClientName = linearAislesClientNames[data[aisleIndex]];
          data[aisleIndex] = aislesClientName ? linearAislesClientNames[data[aisleIndex]] : data[aisleIndex];
        })
      }

      const columnsTranslated = reportsColumns.map(row => getChainMeaning(t, chain, row));

      const formattedDate = format(new Date(inputDate.replaceAll('-', '/')), i18n.language, clientFormats?.date_format)
      const filename = `${inputTaskType}-${replaceWhiteSpace(inputStoreName, '')}-${formattedDate}`;
      if (dataToDownload.length) getDigitalStoreExcel({ excelData: [columnsTranslated, ...dataToDownload], excelFileName: filename, excelDate: formattedDate });
      setIsDownloading(false);
    } else {
      setIsDownloading(false);
    }
  }

  const handleExcelByDateDownload = (isQuickDownload = false) => {
    // If isQuickDownload is true, get the data from the last 3 months, otherwise use the custom dates
    const startTime = isQuickDownload ? new Date(minDate) : startDate
    const endTime = isQuickDownload ? today : endDate

    const offset = today.getTimezoneOffset()
    const parsedStartDate = (new Date(startTime.getTime() - (offset * 60 * 1000))).toISOString().split('T')[0]
    const parsedEndDate = (new Date(endTime.getTime() - (offset * 60 * 1000))).toISOString().split('T')[0]

    const form = {
      client_id: client_id,
      supplier_id: supplier_id,
      store_id: inputStoreId,
      task_type: inputTaskType,
      category: selectedCategory?.category_name,
      start_time: parsedStartDate,
      end_time: parsedEndDate,
      parse_aisles: true,
      selected_date: inputDate
    }

    const excelColumns = displayedColumns ? displayedColumns.filter(row => !filteredColumns.includes(row)) : [];
    if (filteredTaskData?.length && filteredTaskData[0].main_category && !excelColumns.includes('main_category')) {
      excelColumns.push('main_category')
    }

    const fileName = `${inputTaskType}-${replaceWhiteSpace(inputStoreName, '')}-${parsedStartDate}-${parsedEndDate}`;
    getExcelByDate(form, fileName, excelColumns, aislesClientNames, getChainMeaning, t, chain)
  }

  const handlePopoverClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  }

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <>
      <Tooltip title={t('cws_app.general.download', 'Download')} placement="top">
        <Button variant='contained' onClick={handlePopoverClick} aria-describedby={id} 
          sx={{ backgroundColor: 'white',
            borderRadius: '20px',
            height: '40px',
            minWidth: '40px',
            padding: '0',
            color: 'rgba(0, 0, 0, 0.7)' ,
            '&:hover': {
							backgroundColor: 'rgba(0, 0, 0, 0.1)' // Color al hacer hover
						}
          }}>
              <IconComponent iconName={'download-outline'} style={{ fontSize: '20px' }} />
        </Button>
      </Tooltip>
      <Popover
        id={id}
        open={openPopover}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {/* Tabs at the top */}
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tab} onChange={handleTabChange} aria-label="download tabs">
            <Tab label={t('cws_app.digital_store.quick_download', 'Quick Download')} {...a11yProps(0)} />
            <Tab label={t('cws_app.digital_store.custom_download', 'Custom Download')} {...a11yProps(1)} />
          </Tabs>
        </Box>
        {/* First tab */}
        <TabPanel value={tab} index={0}>
          <Grid container spacing={3}>
            <Grid container item direction='column' spacing={1}>
              <Grid item>
                <Typography>{t('cws_app.general.daily_data', 'Daily data')}</Typography>
              </Grid>
              {/* TODO: Disable the buttons if they don't come with all the required params */}
              <Grid container item spacing={1}>
                {canDownloadInv(inputTaskType) &&
                  <Grid item>
                    <Button variant='contained' disabled={isInvLoading} onClick={handleDownloadInv}>
                      {isInvLoading ?
                        <CircularLoader size='2em' />
                        :
                        <Typography>{t('cws_app.general.inv', '.INV')}</Typography>
                      }
                    </Button>
                  </Grid>}
                <Grid item>
                  <Button variant='contained' disabled={isPdfLoading} onClick={handleDownloadPdf}>
                    {isPdfLoading ?
                      <CircularLoader size='1em' />
                      :
                      <Typography>{t('cws_app.general.pdf', 'PDF')}</Typography>
                    }
                  </Button>
                </Grid>
                <Grid item>
                  <Button variant='contained' disabled={isExcelLoading || inputTaskType === 'robot_navigation' || inputTaskType === 'robot_navigation_2_test'} onClick={handleDownloadExcel}>
                    {isExcelLoading ?
                      <CircularLoader size='2em' />
                      :
                      <Typography>{t('cws_app.general.excel', 'Excel')}</Typography>
                    }
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item direction='column' spacing={1}>
              <Grid item>
                <Typography>{t('cws_app.digital_store.last_3_month_data', 'Last 3 months data')}</Typography>
              </Grid>
              <Grid container item>
                <Button variant='contained' disabled={isLoadingExcelByDate || inputTaskType === 'robot_navigation'|| inputTaskType === 'robot_navigation_2_test'} onClick={() => handleExcelByDateDownload(true)}>
                  {isLoadingExcelByDate ? <CircularLoader size='2em' />
                    :
                    <Typography>{t('cws_app.general.excel', 'Excel')}</Typography>
                  }
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </TabPanel>
        {/* Second tab */}
        <TabPanel value={tab} index={1}>
          <Grid container direction='column' spacing={3} alignItems='center'>
            <Grid container item direction='column'>
              <Grid container item spacing={1}>
                <Grid item>
                  <Typography>{t('cws_app.Walls.start_date', 'Start date')}</Typography>
                  <DatePicker date={startDate} setDate={setStartDate} minDate={minDate} inputFormat={checkFormat(clientFormats?.date_format ?? 'yyyy/MM/dd')} />
                </Grid>
                <Grid item>
                  <Typography>{t('cws_app.Walls.end_date', 'End date')}</Typography>
                  <DatePicker date={endDate} setDate={setEndDate} minDate={minDate} inputFormat={checkFormat(clientFormats?.date_format ?? 'yyyy/MM/dd')} />
                </Grid>
              </Grid>
              <Grid item>
                <Typography variant='caption'>*{t('cws_app.digital_store.min_date_data', 'Can only reach up to 3 months of data')}</Typography>
              </Grid>
            </Grid>
            <Grid container item justifyContent='flex-end'>
              <Button variant='contained' disabled={isLoadingExcelByDate || inputTaskType === 'robot_navigation' || inputTaskType === 'robot_navigation_2_test'} onClick={() => handleExcelByDateDownload(false)}>
                {isLoadingExcelByDate && <CircularLoader size='2em' />}
                <Typography>{t('cws_app.general.download_excel', 'Download Excel')}</Typography>
              </Button>
            </Grid>
          </Grid>
        </TabPanel>
      </Popover>
    </>
  );
}
